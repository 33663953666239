import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

const Wrapper = styled.section`
  .container {
    max-width: 1030px !important;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2 {
    color: ${(props) => props.theme.color.text.dark};
    letter-spacing: ${(props) => props.theme.font.spacing.m};
    line-height: ${(props) => props.theme.font.size[40]};
    font-size: ${(props) => props.theme.font.size[40]};
    @media (max-width: 767px) {
      font-size: ${(props) => props.theme.font.size[26]};
    }
  }
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[26]};
    letter-spacing: ${(props) => props.theme.font.spacing.m};
  }
  & h6 {
    font-size: ${(props) => props.theme.font.size[16]};
  }
  & tr {
    height: auto !important;
  }
  & td {
    vertical-align: top;
    height: auto !important;

    & > p {
      color: ${(props) => props.theme.color.text.secondary};
      letter-spacing: ${(props) => props.theme.font.spacing.s};
      line-height: ${(props) => props.theme.font.size[22]};
    }
    &:nth-child(even) {
      margin-top: 2rem;
      @media (min-width: 768px) {
        margin-top: unset;
        padding-left: 8rem;
      }
    }

    @media (max-width: 767px) {
      display: block;
      width: 100% !important;
    }
  }
  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5 {
    color: ${(props) => props.theme.color.text.dark};
  }

  & > p {
    color: ${(props) => props.theme.color.text.secondary};
    letter-spacing: ${(props) => props.theme.font.spacing.s};
    line-height: ${(props) => props.theme.font.size[22]};
  }
`

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location?: any
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <Wrapper className="py-5 mt-5">
    <div className="container">
      <Content content={fields.description} />
    </div>
  </Wrapper>
)

export default Text
