import React from 'react'
import parse from 'html-react-parser'

interface ContentProps {
  content: string
  className: string
}

const Content: React.FC<ContentProps> = ({ content, className }) => (
  <div className={className}>{parse(content)}</div>
)

export default Content
