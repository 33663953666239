/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled from 'styled-components'
import CountUp, { CountUpProps } from 'react-countup'
import handleViewport from 'react-in-viewport'

// Elements
import ParseContent from 'components/shared/ParseContent'

const Wrapper = styled.section``

const Block = styled.div`
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.shadow.main};
`

const Title = styled(CountUp)`
  color: ${(props) => props.theme.color.text.contrast};
  font-size: ${(props) => props.theme.font.size[59]};
  font-weight: ${(props) => props.theme.font.weight.l};
  letter-spacing: ${(props) => props.theme.font.spacing.l};
`

const Content = styled(ParseContent)`
  letter-spacing: ${(props) => props.theme.font.spacing.s};
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[22]};
    line-height: ${(props) => props.theme.font.size[26]};
  }
  & p {
    color: ${(props) => props.theme.color.text.secondary};
    line-height: ${(props) => props.theme.font.size[22]};
    letter-spacing: ${(props) => props.theme.font.spacing.s};
    text-align: left;
  }
`

interface CounterProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Counter
  location?: any
  inViewport: boolean
  forwardedRef: any
}

const Counter: React.FC<CounterProps> = ({ fields }) => {
  const [inViewport, setInViewport] = React.useState<Array<number>>([])

  return (
    <Wrapper>
      <div className="container py-5">
        <div className="row">
          {fields?.block?.map((block, index: number) => {
            const ViewportTitle = handleViewport(CountTitle)

            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className="col-sm-6 col-lg-3 h-100 mb-3 mb-lg-0" key={index}>
                <Block
                  className="p-4 h-100 d-flex align-items-center flex-column flex-grow-1"
                  style={{ minHeight: 416 }}
                >
                  <ViewportTitle
                    className="text-center"
                    onEnterViewport={() => {
                      if (inViewport.indexOf(index) === -1) {
                        const newState = [...inViewport, ...[index]]

                        setInViewport(newState)
                      }
                    }}
                    start={0}
                    end={
                      inViewport.indexOf(index) !== -1 && Number(block?.title)
                    }
                  />

                  <Content content={block?.description} />
                </Block>
              </div>
            )
          })}
        </div>
      </div>
    </Wrapper>
  )
}

interface CountTitleProps extends CountUpProps {
  forwardedRef: any
}

const CountTitle: React.FC<CountTitleProps> = ({
  children,
  forwardedRef,
  ...rest
}) => <Title ref={forwardedRef} {...rest} />

export default Counter
