import React from 'react'

// Components
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface FragmentProps {
  fields: any
  location?: any
}

const Fragment: React.FC<FragmentProps> = ({ fields, location = {} }) => (
  <FlexContentHandler
    prefix="fragment_FlexFragment"
    fields={fields.element.flex_fragment}
    location={location}
  />
)

export default Fragment
