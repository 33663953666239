import React from 'react'
import styled from 'styled-components'
// Components
import GravityForm from 'components/shared/GravityForm'
import ParseContent from 'components/shared/ParseContent'

const StyledForm = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.color.face.light};
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.shadow.main};
  padding: 2.5rem 3rem;
  z-index: 2;
`

const FormTitle = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[24]};
    @media (max-width: 767px) {
      font-size: ${(props) => props.theme.font.size[20]};
    }
  }
  letter-spacing: ${(props) => props.theme.font.spacing.m};
`

const Content = styled(ParseContent)`
  font-size: ${(props) => props.theme.font.size[16]};
  & p > span > div > span > a {
    padding: 0.9rem 3rem;
  }
  & img:hover {
    opacity: 0.7;
  }
`

const GoogleMap = styled.div`
  @media (min-width: 768px) {
    bottom: 100px;
  }
  z-index: 1;
`

interface FormProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
  location?: any
}

const Form: React.FC<FormProps> = ({ fields }) => (
  <section className="my-5">
    <div className="container">
      <div className="row">
        <div className="col-md-6 mb-5 mb-md-0">
          <Content content={fields?.description} />
        </div>
        <div className="col-md-6 mb-5 mb-md-0">
          <StyledForm className="position-relative">
            <FormTitle content={fields?.formtitle} className="mb-4" />
            <GravityForm id={Number(fields.formid)} />
          </StyledForm>
        </div>
        {fields?.hideMap !== true ? (
          <GoogleMap className="position-relative w-100">
            <iframe
              title="tictmap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1085.1862686296204!2d5.381714068345965!3d52.24877777467015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c638ad8519022b%3A0x518f7c2348b33643!2sT-ICT%20Informatie-%20%26%20Communicatietechnologie%20B.V.!5e0!3m2!1snl!2snl!4v1616079986057!5m2!1snl!2snl"
              width="100%"
              height="450"
              style={{ border: 0 }}
              loading="lazy"
            />
          </GoogleMap>
        ) : (
          <div className="col-lg-12 py-3 py-lg-5" />
        )}
      </div>
    </div>
  </section>
)

export default Form
