import React, { useState } from 'react'
import ParseContent from 'components/shared/ParseContent'
import { AnimatePresence, motion } from 'framer-motion'

// Third Party
import styled, { css } from 'styled-components'

// Elements
import ButtonDefaultArrow from 'components/elements/ButtonDefaultArrow'

// Images
import ArrowRight from 'img/arrow-right.inline.svg'
import Plaatjie from '@ubo/plaatjie'

interface BannerAccordionProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerAccordion = styled.section`
  margin-bottom: 4.5rem;
  padding-bottom: 366px;
  @media (min-width: 992px) {
    min-height: 628px;
  }
  @media (max-width: 575px) {
    padding-bottom: 120px;
  }
`

const Image = styled(Plaatjie)`
  height: 100%;
  max-height: 628px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  @media (max-width: 991px) {
    max-height: 380px;
  }
  @media (max-width: 767px) {
    max-height: 350px;
  }
  @media (max-width: 575px) {
    max-height: 175px;
  }
  @media (min-width: 2000px) {
    max-width: 2000px;
    left: calc(50% - 1000px);
  }
  @media (max-width: 1999px) {
    left: 0;
  }
`

const Wrapper = styled.div`
  bottom: -330px;
  @media (max-width: 575px) {
    bottom: -120px;
  }
`

const AccordionContainer = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.shadow.main};
  min-height: 490px;

  & h2 {
    font-size: ${(props) => props.theme.font.size[36]};
    font-weight: ${(props) => props.theme.font.weight.l};
    color: ${(props) => props.theme.color.text.dark};
    letter-spacing: ${(props) => props.theme.font.spacing.m};
    @media (max-width: 575px) {
      font-size: ${(props) => props.theme.font.size[28]};
    }
  }
`

const Accordion = styled.div`
  & h4 {
    font-size: ${(props) => props.theme.font.size[18]};
    letter-spacing: ${(props) => props.theme.font.spacing.l};
    font-weight: ${(props) => props.theme.font.weight.l};
  }
`

const UspTitle = styled(motion.h4)<{ isOpen: boolean }>`
  &:hover {
    color: ${(props) => props.theme.color.text.contrast};
    cursor: pointer;
    & svg {
      fill: ${(props) => props.theme.color.text.contrast};
    }
  }
  color: ${(props) =>
    props.isOpen
      ? props.theme.color.face.contrast
      : props.theme.color.face.dark};
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[59]};
    line-height: ${(props) => props.theme.font.size[70]};
  }
  & p,
  td {
    color: ${(props) => props.theme.color.text.secondary};
    line-height: ${(props) => props.theme.font.size[22]};
  }

  & a {
    box-shadow: ${(props) => props.theme.shadow.main};
    border-radius: 25px;
  }

  & tr {
    vertical-align: top;
    & td {
      @media (max-width: 991px) {
        display: block;
        margin-bottom: 2rem;
        width: 100% !important;
      }
      @media (max-width: 575px) {
        display: inline-block;
      }
      & > img {
        vertical-align: top;
        padding-right: 0.7rem;
        max-width: 95px !important;
      }
      @media (min-width: 1050px) {
        padding-left: 1rem;
      }
    }
  }
`

const StyledButtonDefaultArrow = styled(ButtonDefaultArrow)`
  color: ${(props) => props.theme.color.text.light};
  bottom: -25px;
  left: 90px;
`

const ArrowWrapper = styled(motion.div)<{ active: boolean }>`
  width: 13px;
  height: 13px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 13px;
    height: 13px;
    max-width: 13px;
    max-height: 13px;

    ${(props) =>
      props.active &&
      css`
        fill: ${props.theme.color.face.contrast};
      `}
  }
`

const BannerAccordion: React.FC<BannerAccordionProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(0)

  return (
    <StyledBannerAccordion className="position-relative d-flex align-items-center justify-content-center mt-5">
      <Image alt="banner" image={fields?.image} className="position-absolute" />
      <Wrapper className="position-relative container d-flex align-items-end">
        <div className="row">
          <div className="col-sm-7 col-12">
            <AccordionContainer className="position-relative p-3 p-md-4 p-lg-5">
              <Accordion className="position-relative">
                <h2 className="mb-5 mt-4 mt-lg-0">
                  {fields?.accordion?.title}
                </h2>
                {fields?.accordion?.usp?.map((usp, index: number) => {
                  const isOpen: boolean = index === currentIndex
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="px-lg-4" key={index}>
                      <UspTitle
                        onClick={() => {
                          if (isOpen) {
                            setCurrentIndex(null)
                          } else {
                            setCurrentIndex(index)
                          }
                        }}
                        isOpen={isOpen}
                        whileTap={{ scale: 0.9 }}
                        className="mb-4 d-flex align-items-center"
                      >
                        <ArrowWrapper
                          active={isOpen}
                          initial="collapsed"
                          animate={isOpen ? 'open' : 'collapsed'}
                          variants={{
                            open: {
                              rotate: 90,
                            },
                            collapsed: {
                              rotate: 0,
                            },
                          }}
                        >
                          <ArrowRight />
                        </ArrowWrapper>
                        {usp?.title}
                      </UspTitle>
                      <AnimatePresence initial={false}>
                        {isOpen && (
                          <motion.div
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            initial="collapsed"
                            animate="open"
                            exit="collapsed"
                            variants={{
                              open: {
                                opacity: 1,
                                height: 'auto',
                                overflow: 'hidden',
                              },
                              collapsed: {
                                opacity: 0,
                                height: 0,
                                overflow: 'hidden',
                              },
                            }}
                            transition={{
                              duration: 0.8,
                              ease: [0.04, 0.62, 0.23, 0.98],
                            }}
                          >
                            <Content
                              content={usp?.description}
                              className="pb-4"
                            />
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  )
                })}
              </Accordion>
              {fields?.accordion?.link?.url && (
                <StyledButtonDefaultArrow
                  to={fields?.accordion?.link?.url || '#'}
                  className="position-absolute"
                >
                  {fields?.accordion?.link?.title}
                </StyledButtonDefaultArrow>
              )}
            </AccordionContainer>
          </div>
          {fields.description && (
            <div className="mt-4 mt-md-0 mb-lg-3 col-sm-5 d-flex align-items-center align-items-lg-end">
              <Content content={fields?.description} />
            </div>
          )}
        </div>
      </Wrapper>
    </StyledBannerAccordion>
  )
}

export default BannerAccordion
