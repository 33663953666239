import React from 'react'
import styled from 'styled-components'
import ButtonShell from 'components/elements/ButtonShell'
import { motion } from 'framer-motion'

// Images
import ArrowRight from 'img/arrow-right.inline.svg'

const StyledButton = styled(motion.div)`
  position: relative;
  background-color: ${(props) => props.theme.color.face.dark};
  display: inline-block;
  border-radius: 25px;
  font-weight: ${(props) => props.theme.font.weight.l};
  min-width: 178px;
`

const InnerButton = styled.span`
  & > a {
    padding: 0.9rem 3.6rem 0.9rem 2rem;
    letter-spacing: ${(props) => props.theme.font.spacing.m};
  }

  & svg {
    position: absolute;
    right: 28px;
    width: 17px;
    height: 14px;
    max-width: 17px;
    max-height: 14px;
    fill: ${(props) => props.theme.color.face.light};
  }
`

interface ButtonDefaultProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  children: any
  className?: string
}

const ButtonDefault: React.FC<ButtonDefaultProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
}) => (
  <StyledButton
    className={className}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
  >
    <InnerButton className="d-flex align-items-center">
      {isCustom ? (
        children
      ) : (
        <ButtonShell to={to} isAnchor={isAnchor}>
          {children}
        </ButtonShell>
      )}
      <ArrowRight />
    </InnerButton>
  </StyledButton>
)

export default ButtonDefault
