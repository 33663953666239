import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// Images
import Logo from 'img/logo.inline.svg'

// Components
import CustomLink from 'components/shared/CustomLink'
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  color: ${(props) => props.theme.color.text.main};
`

const Brand = styled(CustomLink)``

const Content = styled(ParseContent)`
  min-width: 164px;
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[18]};
  }
  & p {
    line-height: ${(props) => props.theme.font.size[28]};

    & > a:hover {
      text-decoration: underline;
    }
  }
`

const Image = styled(Plaatjie)`
  & img {
    object-fit: contain !important;
  }
  @media (min-width: 768px) {
    max-height: 73px;
    min-width: 158px;
  }
  @media (max-width: 767px) {
    max-height: 50px;
  }
`

const StyledCustomLink = styled(CustomLink)`
  font-size: ${(props) => props.theme.font.size[12]};

  &:hover {
    text-decoration: underline;
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menuFooter {
            social {
              icon {
                localFile {
                  publicURL
                }
              }
              link {
                title
                url
              }
            }
            block {
              description
            }
            partners {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 100, quality: 100)
                  }
                }
              }
              link {
                title
                url
              }
            }
            document {
              link {
                title
                url
                target
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledFooter>
      <div className="container py-5">
        <div className="row py-5 border-bottom no-gutters">
          <div className="col-xl-9 col-12 d-flex justify-content-center order-xl-2">
            <div className="row no-gutters w-100">
              {fields?.footer?.menuFooter?.block?.map(
                (edge: any, index: number) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className="col-md-3 col-6 d-flex justify-content-center"
                  >
                    <Content content={edge.description} />
                  </div>
                )
              )}
            </div>
          </div>
          <div className="col-xl-3 col-12 d-flex flex-column align-items-center align-items-xl-start order-xl-1 mt-md-0 mt-5">
            <Brand to="/">
              <Logo />
            </Brand>

            <div className="d-flex">
              {fields?.footer?.menuFooter?.social?.map(
                (edge: any, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} className="pr-4 pt-5">
                    <a
                      href={edge.link.url}
                      className="font-0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={edge.icon.localFile.publicURL} alt="Icoon" />
                      {edge.link.title}
                    </a>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div className="row no-gutters pt-2 pb-4">
          <div className="col-12 d-flex align-items-center justify-content-center justify-content-sm-start">
            {fields?.footer?.menuFooter?.partners?.map(
              (edge: any, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={index}>
                  <Image image={edge.image} className="mr-1" alt="Partner" />
                </React.Fragment>
              )
            )}
          </div>
          <div className="col-12 d-flex align-items-center justify-content-center justify-content-sm-end mt-4">
            {fields?.footer?.menuFooter?.document?.map(
              (edge: any, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="ml-0 ml-sm-4 mx-2">
                  <StyledCustomLink to={edge?.link?.url} target={edge?.link?.target || '_self'}>
                    {edge?.link?.title}
                  </StyledCustomLink>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
