import React from 'react'
import styled from 'styled-components'
import Moment from 'react-moment'

// Elements
import CustomLink from 'components/shared/CustomLink'
import ButtonDefault from 'components/elements/ButtonDefault'
import Blogik, {
  BlogConsumer,
  BlogButton,
  BlogFilter,
} from 'components/shared/Blogik'
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'
import ButtonDefaultWhite from 'components/elements/ButtonDefaultWhite'

const StyledButton = styled(ButtonDefaultWhite)`
  & > a {
    padding: 0.3rem 2rem;
  }
`

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  location: any
}

const Blog: React.FC<BlogProps> = ({ fields }) => (
  <section>
    <Blogik
      type={fields?.viewtype}
      settings={{
        id: 'blog',
        limit: Number(fields.limit),
      }}
    >
      <div className="pb-5">
        {/* <BlogFilters /> */}
        <BlogGrid fields={fields} />
      </div>
    </Blogik>
  </section>
)

// const StyledBlogFilter = styled(BlogFilter)<{ selected: boolean }>`
//   font-weight: ${(props) =>
//     props.selected ? props.theme.font.weight.l : props.theme.font.weight.s};
// `

// interface BlogFiltersProps {}

// interface BlogFilterProps {
//   node: {
//     id: string
//     name: string
//   }
// }

// const BlogFilters: React.FC<BlogFiltersProps> = () => (
//   <BlogConsumer>
//     {({ categories, isSelected }: any) => (
//       <div className="container d-flex">
//         {categories.map((category: BlogFilterProps) => (
//           <StyledBlogFilter
//             key={category.node.id}
//             id={category.node.id}
//             selected={isSelected(category.node.id)}
//             className="mr-3"
//           >
//             {category.node.name}
//           </StyledBlogFilter>
//         ))}
//       </div>
//     )}
//   </BlogConsumer>
// )

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => (
  <BlogConsumer>
    {({ posts, showMoreButton }: any) => (
      <div className="container">
        <div className="row">
          {posts.map((post: BlogGridPostProps) => (
            <div
              key={post.node.id}
              className="col-lg-4 col-sm-6 d-flex flex-grow-1 mb-5"
            >
              <BlogGridPost node={post.node} blogFields={fields} />
            </div>
          ))}
        </div>

        {showMoreButton && (
          <div className="mt-5 text-center">
            <ButtonDefault to="/" isCustom>
              <BlogButton>{fields.loadmoretext}</BlogButton>
            </ButtonDefault>
          </div>
        )}
      </div>
    )}
  </BlogConsumer>
)

const StyledPost = styled(CustomLink)`
  border-radius: 10px;
  background-color: ${(props) => props.theme.color.face.contrast};
  color: ${(props) => props.theme.color.text.light} !important;
  &:hover {
    & img {
      opacity: 0.82 !important;
    }
  }
`

const Image = styled(Plaatjie)`
  min-height: 250px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`

// const StyledCustomLink = styled(CustomLink)`
//   color: ${(props) => props.theme.color.text.dark};
// `

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  node: GatsbyTypes.WpPage
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => {
  const { thumbnail } = node.postdetail

  // const date = node?.date ? node?.date : '01/01/2021'

  // const dateToFormat = new Date(date)

  return (
    <StyledPost className="position-relative h-100" to={node.uri}>
      <div className="h-50">
        <Image
          image={thumbnail?.image}
          className="position-relative"
          alt="thumbnail"
        />
      </div>
      <div className="d-flex flex-column justify-content-between pt-5 px-4 pb-4 h-50">
        <div>
          <h5>{node.title}</h5>
        </div>

        {/* <Moment date={dateToFormat} format="D MMMM YYYY" locale="nl" /> */}

        <div>
          {/* <ParseContent content={thumbnail?.shortDescription} /> */}
          <div className="d-flex justify-content-end">
            <StyledButton to={node.uri} className="font-weight-bold mt-4">
              {`${blogFields.readmoretext} >`}
            </StyledButton>
          </div>
        </div>
      </div>
    </StyledPost>
  )
}

export default Blog
