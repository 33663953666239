import React from 'react'
import styled from 'styled-components'
import ButtonShell from 'components/elements/ButtonShell'
import { motion } from 'framer-motion'

const MotionButton = styled(motion.span)``

const StyledButton = styled.span`
  background-color: ${(props) => props.theme.color.face.contrast};
  display: inline-block;
  border-radius: 25px;
  font-weight: ${(props) => props.theme.font.weight.l};
  min-width: 120px;

  & > a,
  & > button {
    color: ${(props) => props.theme.color.text.light} !important;
    padding: 0.6rem 3rem;
    width: 100%;
    height: 100%;
    display: block;
  }
`

interface ButtonDefaultProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  children: any
  className?: string
}

const ButtonDefault: React.FC<ButtonDefaultProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
}) => (
  <MotionButton
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
    className="d-inline-flex"
  >
    <StyledButton className={className}>
      {isCustom ? (
        children
      ) : (
        <ButtonShell to={to} isAnchor={isAnchor}>
          {children}
        </ButtonShell>
      )}
    </StyledButton>
  </MotionButton>
)

export default ButtonDefault
