import React from 'react'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface BannerHomeProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
}

const StyledBannerHome = styled.section`
  display: flex;
  justify-content: center;
  margin-bottom: 4.5rem;
  @media (min-width: 992px) {
    min-height: 725px;
  }
  @media (max-width: 991px) {
    min-height: 515px;
  }
  @media (max-width: 767px) {
    min-height: 400px;
  }
  @media (max-width: 575px) {
    min-height: 350px;
  }
`

const Image = styled(Plaatjie)`
  width: 100%;
  height: 100%;
  top: 0;
  @media (min-width: 992px) {
    border-top-left-radius: 300px;
  }
  @media (min-width: 2000px) {
    max-width: 2000px;
    left: calc(50% - 1000px);
  }
  @media (max-width: 1999px) {
    left: 0;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[59]};
    text-shadow: 1px 1px 7px rgba(0, 0, 0, 1);

    line-height: ${(props) => props.theme.font.size[70]};
    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size[34]};
    }
  }
  & p {
    line-height: ${(props) => props.theme.font.size[30]};
    text-shadow: 1px 1px 7px rgba(0, 0, 0, 1);
    font-size: ${(props) => props.theme.font.size[18]};
    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size[14]};
      line-height: ${(props) => props.theme.font.size[20]};
    }
  }

  & a {
    box-shadow: ${(props) => props.theme.shadow.main};
    font-size: ${(props) => props.theme.font.size[16]};
    text-shadow: none;
    border-radius: 25px;
    @media (max-width: 767px) {
    }
  }
`

const BannerHome: React.FC<BannerHomeProps> = ({ fields }) => (
  <StyledBannerHome className="position-relative d-flex align-items-center justify-content-center">
    <Image alt="banner" image={fields?.image} className="position-absolute" />
    <div className="container position-relative">
      <Content content={fields.description} className="py-3" />
    </div>
  </StyledBannerHome>
)

export default BannerHome
