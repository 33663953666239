/* eslint-disable no-param-reassign */
import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Blogik, { BlogConsumer } from 'components/shared/Blogik'
import { Link } from 'gatsby'

const MainContent = styled(ParseContent)`
  box-shadow: ${(props) => props.theme.shadow.main};
  border-radius: 10px;
  & h1 {
    font-size: ${(props) => props.theme.font.size[26]};
    padding-bottom: 2rem;
  }
  & h2, h3 {
    font-size: ${(props) => props.theme.font.size[16]};
  }

  & p {
    color: ${(props) => props.theme.color.text.secondary};
  }
  & ul {
    color: ${(props) => props.theme.color.text.secondary};
  }

  & a:hover {
    text-decoration: underline;
  }
`
const StyledLink = styled(Link)`
  color: ${(props) => props.theme.color.text.contrast};
  &:hover {
    text-decoration: underline;
  }
`

const Side = styled.div`
  box-shadow: ${(props) => props.theme.shadow.main};
  border-radius: 10px;
`

interface PostBodyProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Postbody
  location?: any
}

const PostBody: React.FC<PostBodyProps> = ({ fields }) => (
  <div className="section mb-5">
    <div className="container">
      <div className="row">
        <div className="col-sm-8 mb-5 mb-sm-0">
          <MainContent content={fields.description} className="p-md-5 p-4" />
        </div>
        <div className="col-sm-4">
          <Side className="p-md-5 p-4">
            <h4 className="text-center font-weight-bold mb-5">NIEUWS</h4>
            <Blogik
              type="cases"
              settings={{
                id: 'blog',
                limit: 1000,
              }}
            >
              <SideContent fields={fields} />
            </Blogik>
          </Side>
        </div>
      </div>
    </div>
  </div>
)

interface SideContentProps {
  fields: any
}

interface SideContentPostProps {
  // eslint-disable-next-line
  node: GatsbyTypes.WpPage
}

const SideContent: React.FC<SideContentProps> = () => (
  <BlogConsumer>
    {({ posts }: any) => <ShufflePosts posts={posts} />}
  </BlogConsumer>
)

interface ShufflePostsProps {
  posts: any
}

const ShufflePosts: React.FC<ShufflePostsProps> = ({ posts }) => {
  const [items, setItems] = React.useState<any>([])

  const shuffleArray = (array: Array<any>) => {
    let i = array.length - 1
    // eslint-disable-next-line no-plusplus
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  }

  React.useEffect(() => {
    const shuffledPosts = shuffleArray(posts)

    setItems(shuffledPosts)
  }, [])

  if (items.length === 0) {
    return null
  }

  return (
    <div>
      <div className="d-flex flex-grow-1 mb-4">
        <SideContentPost node={items[0].node} />
      </div>
      <div className="d-flex flex-grow-1 mb-4">
        <SideContentPost node={items[1].node} />
      </div>
      <div className="d-flex flex-grow-1 mb-4">
        <SideContentPost node={items[2].node} />
      </div>
    </div>
  )
}

const SideContentPost: React.FC<SideContentPostProps> = ({ node }) => (
  <StyledLink className="position-relative" to={node.uri}>
    {node.title}
  </StyledLink>
)

export default PostBody
