import React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import { useMedia } from 'react-use'
import GravityForm from 'components/shared/GravityForm'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonDefault from 'components/elements/ButtonDefault'

const Wrapper = styled.section`
  .container {
    max-width: 1030px !important;
  }
`

const VacatureWrapper = styled.div`
  & ul {
    list-style: unset !important;
  }

  & img {
    min-width: 20px;
    min-height: 20px;
  }
`

const MainContent = styled(ParseContent)`
  box-shadow: ${(props) => props.theme.shadow.main};
  border-radius: 10px;
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[18]};
    letter-spacing: ${(props) => props.theme.font.spacing.s};
  }

  & span {
    line-height: ${(props) => props.theme.font.size[22]};
    letter-spacing: ${(props) => props.theme.font.spacing.m};
  }
`

const SideContent = styled.div`
  box-shadow: ${(props) => props.theme.shadow.main};
  border-radius: 10px;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[24]};
    letter-spacing: ${(props) => props.theme.font.spacing.m};
  }
  & a:hover {
    text-decoration: underline;
  }
`

interface VacatureProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Vacaturebody
  location?: any
}

const Vacature: React.FC<VacatureProps> = ({ fields }) => {
  const [modalOpen, setModalOpen] = React.useState(false)

  const smallDevice = useMedia('(max-width: 575px)')

  const customStyles = {
    content: {
      top: smallDevice ? '65%' : '55%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      paddingBottom: '3rem',
      transform: 'translate(-50%, -50%)',
      width: smallDevice ? '90%' : '80%',
      height: smallDevice ? '70vh' : '70vh',
    },
  }

  return (
    <Wrapper className="py-5">
      <VacatureWrapper className="container">
        <div className="row">
          <div className="col-lg-8 col-md-6">
            <MainContent content={fields?.description} className="p-md-5 p-4" />
          </div>
          <div className="col-lg-4 col-md-6 mt-5 mt-md-0">
            <SideContent className="p-4">
              <ParseContent content={fields?.shortdescription} />
              <div className="pb-5">
                <ButtonDefault to="/" isCustom className="my-5 ">
                  <button
                    type="button"
                    onClick={() => setModalOpen(true)}
                    className="text-white font-weight-bold"
                  >
                    SOLLICITEER DIRECT
                  </button>
                </ButtonDefault>
              </div>
            </SideContent>

            <Modal
              isOpen={modalOpen}
              onRequestClose={() => setModalOpen(false)}
              style={customStyles}
            >
              <GravityForm id={2} className="p-sm-5 " />
            </Modal>
          </div>
        </div>
      </VacatureWrapper>
    </Wrapper>
  )
}

export default Vacature
