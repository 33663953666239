/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

// Slider
import CustomLink from 'components/shared/CustomLink'

// Slider
import Slider from 'react-slick'
import Plaatjie from '@ubo/plaatjie'

const Wrapper = styled.section`
  background-color: ${(props) => props.theme.color.face.main};

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-slider,
  .slick-list {
    width: 100% !important;
  }
`

const Slide = styled.div``

const StyledLink = styled.a`
  &:hover {
    opacity: 0.5;
  }
`

const Image = styled(Plaatjie)`
  max-width: 126px;
  @media (max-width: 767px) {
    max-width: 106px;
  }
  @media (max-width: 575px) {
    max-width: 86px;
  }
`

const SectionWrapper = styled.section`
  background-color: ${(props) => props.theme.color.face.secondary};
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.face.light};
`

interface ClientsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Clients
}

const Clients: React.FC<ClientsProps> = ({ fields }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    accessibility: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <Wrapper className="py-5 mb-5">
      <div className="container">
        <Slider {...settings} className="d-flex align-items-center">
          {fields?.slide?.map((slide: any, index: number) => {
            if (slide?.link?.url)
              return (
                <StyledLink
                  href={slide?.link.url || '#'}
                  className="d-flex align-items-center justify-content-center h-100"
                  target="_blank"
                >
                  <Image image={slide?.image} alt={slide?.link?.title} />
                </StyledLink>
              )
            return (
              <Slide className="d-flex align-items-center justify-content-center h-100">
                <Image image={slide?.image} alt={slide?.link?.title} />
              </Slide>
            )
          })}
        </Slider>
      </div>
    </Wrapper>
  )
}

export default Clients
