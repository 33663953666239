import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonDefaultArrowBlack from 'components/elements/ButtonDefaultArrowBlack'
import Plaatjie from '@ubo/plaatjie'

const Wrapper = styled.section<{ bgColor: string }>`
  background-color: ${(props) =>
    props.bgColor === 'dark'
      ? props.theme.color.face.dark
      : props.theme.color.face.contrast};
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[26]};
    letter-spacing: ${(props) => props.theme.font.spacing.m};
    line-height: ${(props) => props.theme.font.size[36]};
  }
`

const Image = styled(Plaatjie)`
  bottom: -50px;
  @media (max-width: 767px) {
    max-width: 130px;
    right: 0;
    bottom: 0;
    left: calc(50vw - 65px);
  }
`

const StyledButton = styled(ButtonDefaultArrowBlack)`
  color: ${(props) => props.theme.color.text.light};
`

interface CtaWithFileProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Ctawithfile
  location?: any
}

const CtaWithFile: React.FC<CtaWithFileProps> = ({ fields }) => (
  <Wrapper
    className="position-relative mt-5"
    bgColor={fields?.backgroundcolor || '#009EDB'}
  >
    <div className="container py-5">
      <div className="row align-items-center">
        <div className="col-lg-4 col-md-6 col-12">
          <Image
            image={fields?.image}
            className="position-absolute"
            alt="Call to Action"
          />
        </div>
        <div className="col-lg-5 col-md-6 col-12">
          <Content content={fields?.description} />
        </div>
        <div className="col-lg-3 col-12 mt-5 mt-lg-0 d-flex justify-content-center">
          <StyledButton to={fields?.file?.localFile?.publicURL || '#'} isAnchor>
            DOWNLOAD BROCHURE
          </StyledButton>
        </div>
      </div>
    </div>
  </Wrapper>
)

export default CtaWithFile
