import React from 'react'
import styled from 'styled-components'
import { useFlexSearch } from 'react-use-flexsearch'
import { useStaticQuery, graphql } from 'gatsby'
import { motion } from 'framer-motion'
import parse from 'html-react-parser'

// Images
import SearchIcon from 'img/search.inline.svg'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'

const Wrapper = styled.section``

const Input = styled.input`
  background: ${(props) => props.theme.color.face.light};
  border-radius: 29.5px;
  border: 2px solid ${(props) => props.theme.color.face.contrast};
  z-index: 2;
  position: relative;
  padding: 0 10px 0 45px;
  height: 35px;
  width: 300px;
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.font.size[16]} !important;

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
    font-size: ${(props) => props.theme.font.size[16]} !important;
  }
`

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 2;
  height: 26px;
  width: 26px;
  opacity: 0.2;
`

const Row = styled.div`
  min-height: 200px;
`

const SearchResult = styled(motion.div)`
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  margin: 20px 0 20px 0;
`

const Type = styled.p`
  position: relative;
  color: ${(props) => props.theme.color.text.contrast};
  font-weight: bold;

  &::before {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    left: 0;
    bottom: 0;
  }
`

interface SearchBarProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Searchbar
  location?: any
  className?: string
}

const SearchBar: React.FC<SearchBarProps> = ({ className = '' }) => {
  const {
    posts,
    pages,
    cases,
    vacatures,
  } = useStaticQuery<GatsbyTypes.searchQueryQuery>(graphql`
    query searchQuery {
      posts: localSearchPosts {
        index
        store
      }
      pages: localSearchPages {
        index
        store
      }
      cases: localSearchCases {
        index
        store
      }
      vacatures: localSearchVacatures {
        index
        store
      }
    }
  `)

  const [query, setQuery] = React.useState<string | null>('')

  const pageResults = useFlexSearch(query, pages?.index, pages?.store)

  const postResults = useFlexSearch(query, posts?.index, posts?.store)

  const caseResults = useFlexSearch(query, cases?.index, cases?.store)

  const vacatureResults = useFlexSearch(
    query,
    vacatures?.index,
    vacatures?.store
  )

  function handleSearchChange(e: any) {
    setQuery(e.target.value)
  }

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const params = urlParams.get('query')

    setQuery(params)
  }, [])

  return (
    <Wrapper className={`container ${className ? `${className}` : ``}`}>
      <div className="row">
        <div className="col-12 mb-5 d-flex justify-content-center">
          <div className="position-relative">
            <Input
              type="search"
              className=""
              value={query}
              onChange={handleSearchChange}
              placeholder="Zoeken"
            />
            <StyledSearchIcon />
          </div>
        </div>
        <div className="col-12">
          <Row className="row pb-5">
            {query !== null &&
              vacatureResults.map((r: any, index: number) => (
                <div
                  className="col-lg-4 col-sm-6 d-flex flex-grow-1 mb-5"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  <SearchResult
                    initial={{ y: 50, opacity: 0.5 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                      duration: 0.8,
                      type: 'spring',
                    }}
                    className="p-4 w-100 h-100 d-flex flex-column"
                  >
                    <div className="d-flex justify-content-start">
                      <Type>{r.type}</Type>
                    </div>
                    <h3>{parse(r.title)}</h3>
                    <div className="d-flex justify-content-center mt-auto">
                      <ButtonDefault to={r.uri}>
                        Ga naar resultaat
                      </ButtonDefault>
                    </div>
                  </SearchResult>
                </div>
              ))}
            {query !== null &&
              pageResults.map((r: any, index: number) => (
                <div
                  className="col-lg-4 col-sm-6 d-flex flex-grow-1 mb-5"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  <SearchResult
                    initial={{ y: 50, opacity: 0.5 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                      duration: 0.8,
                      type: 'spring',
                    }}
                    className="p-4 w-100 h-100 d-flex flex-column"
                  >
                    <div className="d-flex justify-content-start">
                      <Type>{r.type}</Type>
                    </div>
                    <h3>{parse(r.title)}</h3>
                    <div className="d-flex justify-content-center mt-auto">
                      <ButtonDefault to={r.uri}>
                        Ga naar resultaat
                      </ButtonDefault>
                    </div>
                  </SearchResult>
                </div>
              ))}
            {query !== null &&
              caseResults.map((r: any, index: number) => (
                <div
                  className="col-lg-4 col-sm-6 d-flex flex-grow-1 mb-5"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  <SearchResult
                    initial={{ y: 50, opacity: 0.5 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                      duration: 0.8,
                      type: 'spring',
                    }}
                    className="p-4 w-100 h-100 d-flex flex-column"
                  >
                    <div className="d-flex justify-content-start">
                      <Type>{r.type}</Type>
                    </div>
                    <h3>{parse(r.title)}</h3>
                    <div className="d-flex justify-content-center mt-auto">
                      <ButtonDefault to={r.uri}>
                        Ga naar resultaat
                      </ButtonDefault>
                    </div>
                  </SearchResult>
                </div>
              ))}
            {query !== null &&
              postResults.map((r: any, index: number) => (
                <div
                  className="col-lg-4 col-sm-6 d-flex flex-grow-1 mb-5"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  <SearchResult
                    initial={{ y: 50, opacity: 0.5 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                      duration: 0.8,
                      type: 'spring',
                    }}
                    className="p-4 w-100 h-100 d-flex flex-column"
                  >
                    <div>
                      <div className="d-flex justify-content-start">
                        <Type>{r.type}</Type>
                      </div>
                      <h3>{parse(r.title)}</h3>
                    </div>

                    <div className="d-flex justify-content-center mt-auto">
                      <ButtonDefault to={r.uri}>
                        Ga naar resultaat
                      </ButtonDefault>
                    </div>
                  </SearchResult>
                </div>
              ))}
          </Row>
        </div>
      </div>
    </Wrapper>
  )
}

export default SearchBar
