/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { motion } from 'framer-motion'
import { useMedia } from 'react-use'

// Images
import Logo from 'img/logo.inline.svg'
import SearchIcon from 'img/search.inline.svg'

// Components
import CustomLink from 'components/shared/CustomLink'
import ParseContent from 'components/shared/ParseContent'

const StyledHeader = styled(motion.nav)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 3;
`

const TopMenuContainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
  height: 52px;
  background-color: ${(props) => props.theme.color.face.dark};
  color: ${(props) => props.theme.color.text.light};
`

const MenuContainer = styled.div`
  height: 126px;
  background-color: ${(props) => props.theme.color.face.light};
  font-weight: ${(props) => props.theme.font.weight.l};
  @media (max-width: 991px) {
    height: 86px;
  }
`

const BottomMenuContainer = styled.div`
  background-color: ${(props) => props.theme.color.face.dark};

  & > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    & li {
      & a {
        color: ${(props) => props.theme.color.text.light};
      }
    }
  }
`

const InnerMenuContainer = styled.div`
  max-width: 1190px !important;
`

const Brand = styled(CustomLink)`
  & > svg {
    height: 74px;
    width: 180px;
    @media (max-width: 991px) {
      height: 49px;
      width: 155px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonTitle = styled.div`
  color: ${(props) => props.theme.color.text.dark};
  text-transform: uppercase;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.text.dark};
  height: 2px;
  width: 70%;
  margin: 5px auto;
`

const MenuNotification = styled.div`
  background-color: ${(props) => props.theme.color.face.contrast};
  padding: 10px 0;
  text-align: center;

  & p {
    color: ${(props) => props.theme.color.text.light};
    font-size: 13px;
  }

  & strong {
    font-weight: bold;
  }
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FC<HeaderProps> = ({ setIsScrollable, isSticky }) => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      fields: wpComponent(databaseId: { eq: 97 }) {
        header {
          topMenuHeader {
            featured {
              description
            }
            menu {
              link {
                title
                url
              }
            }
            contact {
              description
            }
          }

          menuHeader {
            menu {
              link {
                title
                url
              }
            }
            featuredButton {
              title
              url
            }
          }
        }
      }
    }
  `)

  return (
    <StyledHeader
      initial={{ boxShadow: 'none' }}
      animate={
        isSticky
          ? {
            boxShadow: '0px 5px 9px 0px rgba(0, 0, 0, 0.1)',
          }
          : { boxShadow: 'none' }
      }
    >
    
      <TopMenuContainer className="d-flex align-items-center">
        <TopMenuDesktop fields={fields} />
      </TopMenuContainer>

      <MenuContainer className="d-flex justify-content-center align-items-center">
        <InnerMenuContainer className="container d-flex justify-content-between align-items-center position-relative">
          <Brand to="/">
            <Logo />
          </Brand>

          <MenuMobile fields={fields} setIsScrollable={setIsScrollable} />
          <MenuDesktop fields={fields} />
        </InnerMenuContainer>
      </MenuContainer>
    </StyledHeader>
  )
}

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 30px 0 0;

  & > ul {
    padding: 50px 0 0;
    list-style-type: none;
    text-align: center;
    width: 60%;
    margin: 0 auto;

    & li {
      padding: 0;
      border-bottom: 1px solid #f1f1f1;

      &:last-child {
        border-bottom: none;
      }

      & a {
        font-size: ${(props) => props.theme.font.size[24]};
        display: block;
        padding: 10px 0;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.text.dark};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

interface MenuMobileProps {
  fields: any
  setIsScrollable: Function
}

const MenuMobile: React.FC<MenuMobileProps> = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const searchForIt = (event: any) => {
    if (event.keyCode === 13) {
      navigate(`/zoeken?query=${event.target.value}`)
    }
  }

  return (
    <div className="d-flex d-lg-none align-items-center">
      <div
        className={`align-items-center ${typeof window !== `undefined` &&
          window.location.href.includes('zoeken')
          ? 'd-none'
          : 'd-none d-sm-flex'
          }`}
      >
        <SiteSearch
          className="mr-2"
          onKeyDown={(e: any) => searchForIt(e)}
          placeholder="Zoeken"
          onClick={(e: any) => e.preventDefault()}
        />
        <StyledSearchIcon className="d-lg-flex d-none" />
      </div>
      <NavButton
        className=""
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(true)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonTitle>Menu</NavButtonTitle>
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <NavButtonCloseCross />
          </NavButtonClose>
          <ul>
            <Menu fields={fields} />
          </ul>
          <BottomMenuMobile fields={fields} />
          <MotionFeaturedItem
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="position-absolute"
          >
            <FeaturedItem
              to={fields.header.menuHeader.featuredButton.url}
              className="text-center py-3 px-5"
            >
              {fields.header.menuHeader.featuredButton.title}
            </FeaturedItem>
          </MotionFeaturedItem>
        </MenuWrapper>
      )}
    </div>
  )
}

const StyledTopMenuDesktop = styled.div`
  flex: 1;
  list-style: none;
  margin-bottom: 0;
  width: 1190px;
  max-width: 1190px !important;

  & ul {
    list-style-type: none;
    margin-bottom: 0;
    padding: 0;
  }
`

const StyledMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
`

interface TopMenuDesktopProps {
  fields: any
}

interface MenuDesktopProps {
  fields: any
}

const TopMenuDesktop: React.FC<TopMenuDesktopProps> = ({ fields }) => (
  <StyledTopMenuDesktop className="container d-flex">
    <TopMenu fields={fields} />
  </StyledTopMenuDesktop>
)

const MenuDesktop: React.FC<MenuDesktopProps> = ({ fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex justify-content-end">
    <Menu fields={fields} />
  </StyledMenuDesktop>
)

const FeaturedContent = styled(ParseContent)`
  font-size: ${(props) => props.theme.font.size[12]};
  & img {
    max-width: 15px;
    max-height: 15px;
  }
  @media (max-width: 575px) {
    font-size: 10px;
  }
`

const ContactContent = styled(ParseContent)`
  color: ${(props) => props.theme.color.text.contrast};

  & img {
    max-width: 15px;
    max-height: 15px;
    &:hover {
      opacity: 0.7;
    }
    @media (max-width: 575px) {
      display: none;
    }
  }

  &:hover {
    color: ${(props) => props.theme.color.text.light};
  }

  @media (max-width: 575px) {
    font-size: ${(props) => props.theme.font.size[12]};
  }
`

const TopMenuItem = styled.li`
  & > a {
    &[aria-current] {
      color: ${(props) => props.theme.color.text.contrast};
    }

    &:hover {
      color: ${(props) => props.theme.color.text.contrast};
    }

    & > img {
      width: 15px;
      height: 15px;
    }

    @media (max-width: 991px) {
      color: ${(props) => props.theme.color.text.light};
    }
  }
`

const Item = styled.li`
  & > a {
    color: ${(props) => props.theme.color.text.dark};
    letter-spacing: ${(props) => props.theme.font.spacing.m};

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 3px;
      bottom: 0;
      left: 0;
      background-color: ${(props) => props.theme.color.text.contrast};
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }

    &[aria-current] {
      &:before {
        visibility: visible;
        width: 100%;
      }
    }

    &:hover:before {
      visibility: visible;
      width: 100%;
    }

    &:hover {
    }
  }
`

const MotionFeaturedItem = styled(motion.div)`
  z-index: 4;
  bottom: -38px;
  right: 10px;
`

const FeaturedItem = styled(CustomLink)`
  background-color: ${(props) => props.theme.color.face.contrast};
  border-radius: 25px;
  color: ${(props) => props.theme.color.text.light};
  width: 178px;

  &:hover {
    color: ${(props) => props.theme.color.text.light};
  }
`

const StyledSearchIcon = styled(SearchIcon)`
  width: 25px;
  height: 25px;
  max-width: 25px;
  max-height: 25px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    & > g > path {
      fill: ${(props) => props.theme.color.face.contrast};
    }
  }
`

const SiteSearch = styled(motion.input)`
  overflow: visible;
  border-radius: 25px;
  padding: 0.6rem 1rem;
  width: 145px;
  border: 2px solid ${(props) => props.theme.color.face.contrast};
  background: rgba(255, 255, 255, 0.95);
  font-size: 16px !important;
  &:focus {
    display: inline !important;
  }

  & svg {
    width: 25px;
    height: 25px;
    max-width: 25px;
    max-height: 25px;
  }

  &:hover {
    cursor: pointer;
    & > svg > g > path {
      fill: ${(props) => props.theme.color.face.contrast};
    }
  }
`

const SearchWrapper = styled(motion.div) <{ isOpen: boolean }>`
  @media (min-width: 992px) {
    ${(props) =>
    props.isOpen
      ? css`
            width: 125px;
            height: 25px;
          `
      : css`
            width: 25px;
          `}
  }
`

const StyledCustomLink = styled(CustomLink)`
  padding: 0.25rem 0.25rem;
`

interface TopMenuProps {
  fields: any
}

interface BottomMenuMobileProps {
  fields: any
}

interface MenuProps {
  fields: any
}

const TopMenu: React.FC<TopMenuProps> = ({ fields }) => (
  <>
    <div className="d-flex align-items-center mr-auto">
      <FeaturedContent
        content={fields.header.topMenuHeader.featured.description}
      />
    </div>
    <div className="d-lg-flex d-none">
      <ul className="d-flex flex-column flex-lg-row align-items-center">
        {fields.header.topMenuHeader.menu.map((edge: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <TopMenuItem key={index} className="pl-xl-5 pl-lg-3">
            <CustomLink to={edge.link.url}>{edge.link.title}</CustomLink>
          </TopMenuItem>
        ))}
      </ul>
    </div>
    <div className="d-flex">
      <ContactContent
        content={fields.header.topMenuHeader.contact.description}
        className="pl-xl-5 pl-lg-3 d-flex align-items-center"
      />
    </div>
  </>
)

const BottomMenuMobile: React.FC<BottomMenuMobileProps> = ({ fields }) => (
  <>
    <BottomMenuContainer className="container d-flex align-items-center mt-5">
      <ul className="row py-4 w-100 justify-content-center">
        {fields.header.topMenuHeader.menu.map((edge: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <TopMenuItem key={index} className="px-3 my-2">
            <CustomLink to={edge.link.url}>{edge.link.title}</CustomLink>
          </TopMenuItem>
        ))}
      </ul>
    </BottomMenuContainer>
    <MotionFeaturedItem whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
      <FeaturedItem
        to={fields.header.menuHeader.featuredButton.url}
        className="text-center py-3 px-5 ml-5"
      >
        {fields.header.menuHeader.featuredButton.title}
      </FeaturedItem>
    </MotionFeaturedItem>
  </>
)

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const [isOpen, setOpen] = React.useState(false)
  const [isFocused, setFocused] = React.useState(false)
  const isMobile = useMedia('(max-width: 991px)')

  const searchForIt = (event: any) => {
    if (event.keyCode === 13) {
      navigate(`/zoeken?query=${event.target.value}`)
      setOpen(false)
      setFocused(false)
    }
  }

  return (
    <>
      {fields.header.menuHeader.menu.map((edge: any, index: number) => (
        <Item
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className="position-relative d-flex align-items-center justify-content-center mx-xl-4 mx-lg-3 py-1"
        >
          <StyledCustomLink to={edge.link.url} className="">
            {edge.link.title}
          </StyledCustomLink>
        </Item>
      ))}
      <SearchWrapper
        isOpen={isOpen || isFocused}
        onClick={() => (isMobile ? navigate(`/zoeken`) : '')}
        onTapStart={() => setOpen(true)}
        onTapCancel={() => setOpen(false)}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        className={`justify-content-center ${isOpen || isFocused ? 'position-absolute' : 'position-relative'
          } align-items-center ml-lg-4  ${typeof window !== `undefined` &&
            window.location.href.includes('zoeken')
            ? 'd-none'
            : 'd-flex'
          }`}
        transition={{ type: 'spring' }}
      >
        <SiteSearch
          className="mt-3 mt-lg-0 pl-lg-4 position-absolute text-center text-lg-left"
          initial="collapsed"
          animate={isOpen || isFocused ? 'open' : 'collapsed'}
          exit="collapsed"
          type="search"
          variants={{
            open: {
              y: 0,
              transition: {
                duration: 0.3,
                ease: [0.04, 0.62, 0.23, 0.98],
              },
              display: 'flex',
              opacity: 1,
            },
            collapsed: {
              y: 200,
              display: isMobile ? 'block' : 'none',
              opacity: 0,
            },
          }}
          onKeyDown={(e: any) => searchForIt(e)}
          placeholder="Zoeken"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />

        <StyledSearchIcon
          style={{ zIndex: 9, right: 0 }}
          className={`position-relative ${isFocused || isOpen ? 'd-none' : 'd-none d-lg-inline'
            }`}
        />
      </SearchWrapper>

      <MotionFeaturedItem
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="position-absolute"
      >
        <FeaturedItem
          to={fields.header.menuHeader.featuredButton.url}
          className="text-center py-3 px-5"
        >
          {fields.header.menuHeader.featuredButton.title}
        </FeaturedItem>
      </MotionFeaturedItem>
    </>
  )
}

export default Header
