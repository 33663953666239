import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'
import TitleDefault from 'components/elements/TitleDefault'
import Plaatjie from '@ubo/plaatjie'

const Block = styled.div`
  z-index: 1;
  border-radius: 6px;
`

const ImageOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(2, 5, 19);
  background: radial-gradient(
    circle,
    rgba(2, 5, 19, 0.57) 0%,
    rgba(0, 0, 0, 0.57) 100%
  );
  z-index: -1;
  border-radius: 6px;
`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;

  & img {
    border-radius: 6px !important;
  }
`

const Content = styled(ParseContent)`
  z-index: 2;
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[10]};
  }

  & p {
    font-size: ${(props) => props.theme.font.size[18]};
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: ${(props) => props.theme.color.face.light};
    &:last-child {
      overflow: visible;
    }
    @media (max-width: 767px) {
      font-size: ${(props) => props.theme.font.size[14]};
    }
  }

  & a {
    padding: 0.3rem 0.5rem;
    font-size: ${(props) => props.theme.font.size[12]};
    text-align: center;
  }
`

interface LinksWithBackgroundImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Linkswithbackgroundimage
}

const LinksWithBackgroundImage: React.FC<LinksWithBackgroundImageProps> = ({
  fields,
}) => (
  <section className="py-5">
    <div className="container">
      <TitleDefault>{fields?.title}</TitleDefault>
      <div className="row my-5">
        {fields?.item?.map((item, index: number) => {
          // let link
          // if (typeof document !== 'undefined') {
          //   link = document.getElementsByTagName('a')[0].getAttribute('href')
          // }
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="col-sm-4 mb-3 mb-sm-0" key={index}>
              <motion.div
                whileHover={{ opacity: 0.7 }}
                whileTap={{ opacity: 0.7 }}
                className="h-100"
              >
                <Block className="d-flex flex-column position-relative p-3 p-lg-4 h-100">
                  <ImageOverlay />
                  <ImageContainer className="position-absolute">
                    <Plaatjie
                      image={item?.image}
                      className="h-100 w-100"
                      alt="Link afbeelding"
                    />
                  </ImageContainer>
                  <Content
                    content={item?.description}
                    className="d-flex flex-column justify-content-between h-100 position-relative"
                  />
                </Block>
              </motion.div>
            </div>
          )
        })}
      </div>
    </div>
  </section>
)

export default LinksWithBackgroundImage
