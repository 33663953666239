import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

const Background = styled.div<{ bgColor: string }>`
  background-color: ${(props) =>
    props.bgColor === 'dark'
      ? props.theme.color.face.dark
      : props.theme.color.face.contrast};
  border-top-left-radius: 110px;
  &:before {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 2000px;
    background-color: ${(props) =>
      props.bgColor === 'dark'
        ? props.theme.color.face.dark
        : props.theme.color.face.contrast};
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[24]};
    letter-spacing: ${(props) => props.theme.font.spacing.m};
    line-height: ${(props) => props.theme.font.size[36]};
  }

  & table {
    width: 100%;
    & td {
      @media (max-width: 767px) {
        width: 100% !important;
        display: block;
        margin-top: 1rem;
      }
    }
  }
  @media (max-width: 767px) {
    font-size: ${(props) => props.theme.font.size[14]};
  }
`

const IconContainer = styled.div`
  background-color: ${(props) => props.theme.color.face.contrast};
  border-radius: 10px;

  @media (min-width: 992px) {
    left: -40px;
  }
  @media (max-width: 991px) {
    left: -125px;
  }
  @media (max-width: 767px) {
    top: -62px;
    left: -62px;
    & img {
      width: 25px;
    }
  }
`

interface CtaProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Cta
  location?: any
}

const Cta: React.FC<CtaProps> = ({ fields }) => (
  <section className="position-relative mt-5">
    <div
      className="position-absolute w-100 h-100"
      style={{ left: 0, right: 0 }}
    >
      <div className="container h-100">
        <div className="row h-100 justify-content-end">
          <Background
            className="col-11 col-lg-8 h-100 position-relative"
            bgColor={fields?.backgroundcolor || ''}
          />
        </div>
      </div>
    </div>
    <div className="container py-5 px-4">
      <div className="row h-100 justify-content-end">
        <div className="col-10 col-lg-8 d-flex position-relative justify-content-end flex-lg-row flex-column">
          {fields?.icon && (
            <IconContainer className="position-absolute p-4">
              <img src={fields?.icon.localFile?.publicURL} alt="" />
            </IconContainer>
          )}
          <Content content={fields?.description} className="w-90" />
        </div>
      </div>
    </div>
  </section>
)

export default Cta
