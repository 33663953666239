import React from 'react'
import styled from 'styled-components'

const Title = styled.h2`
  position: relative;
  font-size: ${(props) => props.theme.font.size[28]};
  font-weight: ${(props) => props.theme.font.weight.l};
  letter-spacing: ${(props) => props.theme.font.spacing.s};

  &:after {
    content: '';
    position: absolute;
    width: 90%;
    height: 3px;
    bottom: -15px;
    left: 11px;
    background-color: ${(props) => props.theme.color.text.contrast};
  }
`

interface TitleDefaultProps {
  className?: string
  children: any
}

const TitleDefault: React.FC<TitleDefaultProps> = ({
  className = '',
  children,
}) => (
  <div className="d-flex justify-content-center">
    <Title className={className}>{children}</Title>
  </div>
)

export default TitleDefault
