import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import Comment from 'img/comment.svg'
import Plaatjie from '@ubo/plaatjie'

const ImageContainer = styled.div`
  margin-top: 6rem;
`

const Image = styled(Plaatjie)`
  z-index: -1;
  @media (min-width: 992px) {
    min-height: 430px;
  }
  @media (max-width: 991px) {
    min-height: 300px;
  }
  @media (max-width: 575px) {
    min-height: unset;
  }
`

const Content = styled(ParseContent)`
  background-color: ${(props) => props.theme.color.face.light};
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.shadow.main};
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    color: ${(props) => props.theme.color.text.dark};
    font-size: ${(props) => props.theme.font.size[26]};
    letter-spacing: ${(props) => props.theme.font.spacing.m};
  }

  @media (min-width: 992px) {
    right: calc(-50% - 30px);
  }
  @media (max-width: 991px) {
    right: calc(-25% - 30px);
  }
  @media (max-width: 767px) {
    right: 0;
  }
  @media (max-width: 575px) {
    right: 0;
  }

  & p {
  }

  ul {
    @media (min-width: 767px) {
      padding-left: 1rem;
    }
    li {
      display: flex;
      vertical-align: middle;
      font-size: ${(props) => props.theme.font.size[18]};
      margin-bottom: 1.2rem;
      letter-spacing: ${(props) => props.theme.font.spacing.l};
      color: ${(props) => props.theme.color.text.secondary};

      &:before {
        content: url(${Comment});
        margin-right: 0.9rem;
        @media (max-width: 767px) {
        }
      }
    }
  }
`

interface TextWithImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Textwithimage
  location?: any
}

const TextWithImage: React.FC<TextWithImageProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <div className="row">
        <div className="col-sm-6 col-lg-5 order-2 ">
          <div className="p-lg-3">
            <Content
              content={fields.description}
              className="position-relative p-4 p-lg-5"
            />
          </div>
        </div>
        <ImageContainer className="col-sm-6 col-lg-7 order-sm-2">
          <Image image={fields?.image} alt="T-ICT" />
        </ImageContainer>
      </div>
    </div>
  </section>
)

export default TextWithImage
