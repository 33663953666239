import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const Title = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[40]};
    letter-spacing: ${(props) => props.theme.font.spacing.l};
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[18]};
    line-height: ${(props) => props.theme.font.size[26]};
    @media (max-width: 575px) {
      font-size: ${(props) => props.theme.font.size[14]};
      line-height: ${(props) => props.theme.font.size[16]};
    }
  }
`

const Image = styled(Plaatjie)`
  border-radius: 10px;
  /* min-height: 250px; */

  @media (min-width: 992px) {
    height: 270px;
    width: 240px;
  }
  @media (max-width: 991px) {
    height: 256px;
    width: 226px;
  }
  @media (max-width: 575px) {
    height: 162px;
    width: 132px;
  }
`

interface TeamProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Team
  location?: any
}

const Team: React.FC<TeamProps> = ({ fields }) => (
  <section className="my-5">
    <div className="container">
      <Title content={fields?.title} />

      <div className="row mt-5">
        {/* <ParseContent content={fields?.title} /> */}
        {fields?.member?.map((member, index: number) => (
          <div
            className="col-md-4 d-flex flex-column justify content-center col-lg-3 col-6 mb-5"
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <Image image={member?.image} alt="teamlid" />
            <Content content={member?.description} className="mt-2" />
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default Team
