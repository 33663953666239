/* eslint-disable react/jsx-no-bind */
// eslint-disable-next-line react/jsx-no-bind
import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import styled from 'styled-components'

// Components
import TitleDefault from 'components/elements/TitleDefault'
import ButtonDefault from './ButtonDefaultArrowBlack'

const StyledButton = styled.button`
  position: relative;
  background: ${(props) => props.theme.color.face.main};
  border-radius: 29.5px;
  display: inline-block;
  height: 45px;
  color: ${(props) => props.theme.color.text.light};
  font-weight: ${(props) => props.theme.font.weight.xl};
  width: 200px;
`

const ButtonDefaultBlack = styled(ButtonDefault)`
  color: ${(props) => props.theme.color.text.light};
  text-transform: uppercase;
`

const StyledModal = styled(Modal)`
  position: fixed;
  overflow: auto;
  outline: none;
  width: 520px;
  height: 220px;
  border-top-right-radius: 10px;
  background: ${(props) => props.theme.color.face.contrast};
  bottom: 0;
  margin-right: 0;
  z-index: 99;
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 13%);
  animation: slidein 4s ease-in-out;

  @keyframes slidein {
    0% {
      left: -1000px;
    }
    100% {
      left: -1px;
    }
  }

  @media (max-width: 991px) {
    width: 80%;

    & h2 {
      font-size: 18px;
    }
  }
`

const CloseButton = styled.button`
  background: ${(props) => props.theme.color.face.dark};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
`

const CloseDiv = styled.div`
  padding-right: 8px;
  padding-top: 8px;
`

function App() {
  const [modalIsOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      !sessionStorage.getItem('hide') && setIsOpen(true)
    }, 5000)
  }, [])

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    sessionStorage.setItem('hide', 'true')
    setIsOpen(false)
  }

  return (
    <div>
      <StyledButton onClick={openModal}>Download brochure</StyledButton>
      <StyledModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div>
          <CloseDiv className="text-right d-flex justify-content-end">
            <CloseButton
              type="button"
              onClick={closeModal}
              className="text-white p-3"
            >
              <span>X</span>
            </CloseButton>
          </CloseDiv>
          <div className="sc-fznZeY kcXlwt row px-2 px-lg-4 m-0 align-items-center">
            <div className="col-lg-12">
              <TitleDefault className="mb-4 text-white font-size-l">
                Ontvang onze nieuwe 3-delige mailserie over security
              </TitleDefault>
            </div>
            <div className="col-lg-1 d-none d-lg-block" />
            <div className="col-lg-12">
              <ButtonDefaultBlack to="/mailserie-security/">
                <a onClick={closeModal}>Schrijf je nu in!</a>
              </ButtonDefaultBlack>
            </div>
          </div>
        </div>
      </StyledModal>
    </div>
  )
}

export default App
