import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Helmet } from 'react-helmet-async'

// Icon
import favicon from 'img/favicon.png'

const removeHTML = (content: string) => {
  // new
  let newContent = content

  if (typeof document !== 'undefined') {
    const titleHtml = newContent
    const div = document.createElement('div')
    div.innerHTML = titleHtml
    newContent = div.innerText
  }

  return newContent
}

interface SEOProps {
  seo: any
}

const SEO: React.FC<SEOProps> = ({ seo }) => {
  const {
    site,
    wpComponent,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.socialSharingQueryQuery>(graphql`
    query socialSharingQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
      wpComponent(databaseId: { eq: 204 }) {
        socialSharing {
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  const defaultImageUrl = wpComponent?.socialSharing?.image
    ? {
      url: `${site?.siteMetadata?.siteUrl}${wpComponent?.socialSharing?.image?.localFile?.publicURL}`,
    }
    : ''

  const image: any = seo.opengraphImage
    ? {
      url: seo.opengraphImage.sourceUrl,
      width: 800,
      height: 600,
      alt: seo.opengraphTitle,
    }
    : defaultImageUrl

  return (
    <>
      <GatsbySeo
        language="nl"
        title={removeHTML(seo.title) || ''}
        description={removeHTML(seo.metaDesc) || ''}
        canonical={seo.canonical || ''}
        openGraph={{
          type: seo.opengraphTitle || '',
          url: seo.opengraphUrl || '',
          title: removeHTML(seo.title) || '',
          description: removeHTML(seo.metaDesc) || '',
          images: [image],
        }}
      />
      <Helmet>
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="d92c64bf-2266-4f1f-bcf0-0007f9162ad8"
          type="text/javascript"
          async
        />
        <link rel="stylesheet" href="https://use.typekit.net/rpi5dsg.css" />
        <link rel="icon" href={favicon} />
      </Helmet>
    </>
  )
}

export default SEO
