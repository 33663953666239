import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'
import TitleDefault from 'components/elements/TitleDefault'
import CustomLink from 'components/shared/CustomLink'

// Images
import ArrowRight from 'img/arrow-right.inline.svg'
import Plaatjie from '@ubo/plaatjie'

interface SolutionsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Solutions
  location?: any
}

const StyledTitleDefault = styled(TitleDefault)`
  margin-bottom: 6rem;
`

const Solution = styled(motion.div)`
  border-radius: 10px;
  padding: 1.875rem 2.438rem;

  @media (max-width: 991px) {
    padding: 0.975rem 1.238rem;
  }

  @media (max-width: 767px) {
    padding: 0.675rem 0.938rem;
  }

  @media (max-width: 575px) {
    padding: 0.175rem 0.438rem;
  }

  &:hover {
    box-shadow: ${(props) => props.theme.shadow.main};
  }
`

const StyledCustomLink = styled(CustomLink)`
  width: 100%;
  height: 100%;
`

const IconWrapper = styled.div`
  width: 39px;
  height: 39px;

  & img {
    object-fit: contain;
  }
`

const Content = styled(ParseContent)`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: ${(props) => props.theme.font.size[15]};
    letter-spacing: ${(props) => props.theme.font.spacing.l};
  }

  p {
    line-height: ${(props) => props.theme.font.size[20]};
    color: ${(props) => props.theme.color.text.secondary};
    @media (max-width: 767px) {
      font-size: ${(props) => props.theme.font.size[12]};
    }
  }
`

const ArrowMotion = styled(motion.div)`
  & > svg {
    width: 13px;
    height: 13px;
    max-width: 13px;
    max-height: 13px;
    fill: ${(props) => props.theme.color.face.contrast};
  }
`

const Text: React.FC<SolutionsProps> = ({ fields }) => (
  <section className="container my-5">
    {fields.title && <StyledTitleDefault>{fields?.title}</StyledTitleDefault>}
    <div className="row">
      {fields.solution?.map((solution, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="col-md-4 col-6 mb-2" key={index}>
          <Solution
            whileHover="hover"
            whileTap={{ scale: 0.9 }}
            initial="initial"
            className="h-100"
          >
            <StyledCustomLink to={solution?.link?.url || '#'}>
              <IconWrapper className="mb-3 mt-lg-0 mt-3">
                <Plaatjie
                  image={solution?.icon}
                  alt={solution?.link?.title || 'Icoon'}
                />
              </IconWrapper>
              <Content content={solution?.description} />
              <AnimatePresence>
                <ArrowMotion
                  className="mt-3"
                  variants={{
                    initial: { opacity: 0 },
                    hover: { opacity: 1 },
                  }}
                >
                  <ArrowRight />
                </ArrowMotion>
              </AnimatePresence>
            </StyledCustomLink>
          </Solution>
        </div>
      ))}
    </div>
  </section>
)

export default Text
