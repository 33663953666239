import React from 'react'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerDefault = styled.section<{ hasInfoSection: number }>`
  display: flex;
  justify-content: center;
  width: 100%;
  @media (min-width: 992px) {
    min-height: 455px;
  }
  @media (max-width: 991px) {
    min-height: 370px;
  }
  @media (max-width: 767px) {
    min-height: 360px;
  }
  @media (max-width: 575px) {
    min-height: 350px;
    ${(props) =>
      props.hasInfoSection === 1 &&
      css`
        margin-bottom: 8rem;
      `}
  }
`

const Image = styled(Plaatjie)`
  max-width: 2000px;
  width: 100%;
  height: 100%;
  top: 0;
  @media (min-width: 992px) {
    border-top-left-radius: 300px;
  }
  @media (min-width: 2000px) {
    max-width: 2000px;
    left: calc(50% - 1000px);
  }
  @media (max-width: 1999px) {
    left: 0;
  }
`

const Content = styled(ParseContent)`
  text-shadow: 1px 1px 7px rgba(0, 0, 0, 1);
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[30]};
    line-height: ${(props) => props.theme.font.size[36]};
    letter-spacing: ${(props) => props.theme.font.spacing.xl};
  }
  & p {
    line-height: ${(props) => props.theme.font.size[30]};
  }

  & a {
    box-shadow: ${(props) => props.theme.shadow.main};
    border-radius: 25px;
  }
`

const Info = styled.div`
  right: 0;
  bottom: -172px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.color.face.light};
  box-shadow: ${(props) => props.theme.shadow.main};
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    line-height: ${(props) => props.theme.font.size[28]};
    font-size: ${(props) => props.theme.font.size[24]};
    letter-spacing: ${(props) => props.theme.font.spacing.s};
    @media (max-width: 575px) {
      font-size: ${(props) => props.theme.font.size[20]};
    }
  }
  @media (max-width: 767px) {
    bottom: -267px;
  }
  @media (max-width: 575px) {
    margin-bottom: 4rem;
  }
`

const BannerDefault: React.FC<BannerDefaultProps> = ({ fields }) => (
  <StyledBannerDefault
    hasInfoSection={fields.info ? 1 : 0}
    className="position-relative d-flex align-items-center align-items-md-end justify-content-center"
  >
    <Image alt="banner" image={fields?.image} className="position-absolute" />
    <div className="container position-relative mb-5">
      <Content content={fields.description} />
      {fields?.info && (
        <Info
          className="position-absolute py-3 px-3 py-sm-4 px-sm-5 mr-2"
          style={{ right: 0 }}
        >
          <ParseContent content={fields?.info} />
        </Info>
      )}
    </div>
  </StyledBannerDefault>
)

export default BannerDefault
