/* eslint-disable no-underscore-dangle */
import React from 'react'

// Lazy loading
// import loadable from '@loadable/component'

// Elements
// import Form from 'components/flex/Form'
// import Text from 'components/flex/Text'
// import Fragment from 'components/flex/Fragment'

// Flexible Content
import BannerShell from 'components/flex/Banner/BannerShell'
import Breadcrumbs from 'components/related/Breadcrumbs'
import VacatureBody from 'components/flex/Text/VacatureBody'
import PostBody from 'components/flex/Text/PostBody'
import Solutions from 'components/flex/Solutions'
import Clients from 'components/flex/Slider/Clients'
import Cases from 'components/flex/Slider/Cases'
import LinksWithBackgroundImage from 'components/flex/LinksWithBackgroundImage'
import Cta from 'components/flex/CTA/CtaDefault'
import CtaWithFile from 'components/flex/CTA/CtaWithFile'
import Counter from 'components/flex/Counter'
import Team from 'components/flex/Team'
import Accordion from 'components/flex/Accordion'
import Form from 'components/flex/Form'
import TextWithImage from 'components/flex/Text/TextWithImage'
import Text from 'components/flex/Text/Text'
import SeachBar from 'components/flex/SearchBar'
import Blog from 'components/flex/Blog'
import Fragment from 'components/flex/Fragment'

interface FlexContentProps {
  prefix?: string
  fields: any
  location?: any
}

interface FlexerProps {
  [key: string]: any
}

interface ComponentProps {
  fields: any
  location?: any
}

const FlexContentHandler: React.FC<FlexContentProps> = ({
  prefix = 'page_Flexcontent',
  fields,
  location = {},
}) => {
  const flexers: FlexerProps = {
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Breadcrumbs`]: Breadcrumbs,
    [`${prefix}_Flex_Vacaturebody`]: VacatureBody,
    [`${prefix}_Flex_Postbody`]: PostBody,
    [`${prefix}_Flex_Solutions`]: Solutions,
    [`${prefix}_Flex_Clients`]: Clients,
    [`${prefix}_Flex_Cases`]: Cases,
    [`${prefix}_Flex_Linkswithbackgroundimage`]: LinksWithBackgroundImage,
    [`${prefix}_Flex_Cta`]: Cta,
    [`${prefix}_Flex_Ctawithfile`]: CtaWithFile,
    [`${prefix}_Flex_Counter`]: Counter,
    [`${prefix}_Flex_Team`]: Team,
    [`${prefix}_Flex_Accordion`]: Accordion,
    [`${prefix}_Flex_Form`]: Form,
    [`${prefix}_Flex_Text`]: Text,
    [`${prefix}_Flex_Searchbar`]: SeachBar,
    [`${prefix}_Flex_Textwithimage`]: TextWithImage,
    [`${prefix}_Flex_Blog`]: Blog,
    [`${prefix}_Flex_Fragment`]: Fragment,

    // const flexers: FlexerProps = {
    //   [`${prefix}_Flex_Banner`]: loadable(
    //     () => import('components/flex/Banner/BannerShell')
    //   ),
    //   [`${prefix}_Flex_Breadcrumbs`]: loadable(
    //     () => import('components/related//Breadcrumbs')
    //   ),
    //   [`${prefix}_Flex_Vacature`]: loadable(
    //     () => import('components/flex/Vacature/Vacature')
    //   ),
    //   [`${prefix}_Flex_Solutions`]: loadable(
    //     () => import('components/flex/Solutions')
    //   ),
    //   [`${prefix}_Flex_Clients`]: loadable(
    //     () => import('components/flex/Slider/Clients')
    //   ),
    //   [`${prefix}_Flex_Cases`]: loadable(
    //     () => import('components/flex/Slider/Cases')
    //   ),
    //   [`${prefix}_Flex_LinksWithBackgroundImage`]: loadable(
    //     () => import('components/flex/LinksWithBackgroundImage')
    //   ),
    //   [`${prefix}_Flex_Cta`]: loadable(
    //     () => import('components/flex/CTA/CtaDefault')
    //   ),
    //   [`${prefix}_Flex_CtaWithFile`]: loadable(
    //     () => import('components/flex/CTA/CtaWithFile')
    //   ),
    //   [`${prefix}_Flex_Counter`]: loadable(
    //     () => import('components/flex/Counter')
    //   ),
    //   [`${prefix}_Flex_Team`]: loadable(() => import('components/flex/Team')),
    //   [`${prefix}_Flex_Accordion`]: loadable(
    //     () => import('components/flex/Accordion')
    //   ),
    //   [`${prefix}_Flex_Form`]: loadable(() => import('components/flex/Form')),
    //   [`${prefix}_Flex_Text`]: loadable(
    //     () => import('components/flex/Text/Text')
    //   ),
    //   [`${prefix}_Flex_TextWithImage`]: loadable(
    //     () => import('components/flex/Text/TextWithImage')
    //   ),
    //   [`${prefix}_Flex_Blog`]: loadable(() => import('components/flex/Blog')),
    //   [`${prefix}_Flex_Fragment`]: loadable(
    //     () => import('components/flex/Fragment')
    //   ),
    // }
  }

  if (!fields.flex) {
    return null
  }

  return (
    <>
      {fields.flex.map((section: any, index: number) => {
        if (!section.fieldGroupName || !flexers[section.fieldGroupName]) {
          return null
        }

        const Component: React.FC<ComponentProps> =
          flexers[section.fieldGroupName]

        return (
          <Component
            // eslint-disable-next-line react/no-array-index-key
            key={`${location ? location.pathname : 'flex'}-${index}`}
            fields={section}
            location={location}
          />
        )
      })}
    </>
  )
}

export default FlexContentHandler
