import React from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'

const Wrapper = styled.section`
  & .container {
    max-width: 1030px !important;
  }
`

const AccordionWrapper = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  box-shadow: ${(props) => props.theme.shadow.main};
  border-radius: 10px;
`

const TitleWrapper = styled(motion.div)`
  &:hover {
    cursor: pointer;
  }
`

const Title = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[16]};
  }
`

const AfterTitle = styled.div`
  font-size: ${(props) => props.theme.font.size[59]};
  color: ${(props) => props.theme.color.text.contrast};
`

const Content = styled(ParseContent)``

interface AccordionProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Accordion
  location?: any
}

const Accordion: React.FC<AccordionProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = React.useState<number | null>(0)

  return (
    <Wrapper className="py-5 my-5">
      <AccordionWrapper className="container p-5">
        {fields?.content?.map((content, index: number) => {
          const isOpen: boolean = index === currentIndex

          return (
            <div className="border-bottom">
              <TitleWrapper
                onClick={() => {
                  if (isOpen) {
                    setCurrentIndex(null)
                  } else {
                    setCurrentIndex(index)
                  }
                }}
                isOpen={isOpen}
                className="d-flex align-items-center justify-content-between px-3"
              >
                <Title content={content?.title} />
                <AfterTitle>{isOpen ? '-' : '+'}</AfterTitle>
              </TitleWrapper>
              <AnimatePresence initial={false}>
                {isOpen && (
                  <motion.div
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: {
                        opacity: 1,
                        height: 'auto',
                        overflow: 'hidden',
                      },
                      collapsed: {
                        opacity: 0,
                        height: 0,
                        overflow: 'hidden',
                      },
                    }}
                    transition={{
                      duration: 0.8,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <Content content={content?.description} className="py-4" />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )
        })}
      </AccordionWrapper>
    </Wrapper>
  )
}

export default Accordion
