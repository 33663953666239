const mainTheme = {
  color: {
    face: {
      main: '#F1F1F1',
      contrast: '#009EDB',
      secondary: '#00A8E3',
      light: '#FFFFFF',
      dark: '#2F2C2D',
    },

    text: {
      main: '#2F2C2D',
      contrast: '#009EDB',
      secondary: '#676767',
      light: '#FFFFFF',
      dark: '#2F2C2D',
    },
  },

  font: {
    family: {
      main: 'myriad-pro, sans-serif',
      secondary: 'Verdana, Arial',
    },

    size: {
      8: '8px',
      10: '10px',
      12: '12px',
      14: '14px',
      15: '15px',
      16: '16px',
      18: '18px',
      20: '20px',
      22: '22px',
      24: '24px',
      26: '26px',
      28: '28px',
      30: '30px',
      32: '32px',
      34: '34px',
      36: '36px',
      40: '40px',
      45: '45px',
      59: '59px',
      70: '70px',
    },

    weight: {
      xs: 200,
      s: 300,
      m: 400,
      l: 700,
      xl: 800,
    },

    spacing: {
      xs: '0.31px',
      s: '0.47px',
      m: '0.66px',
      l: '1px',
      xl: '2px',
    },
  },

  shadow: {
    main: '0px 0px 2px 2px rgba(0, 0, 0, 0.13);',
  },
}

export default mainTheme
