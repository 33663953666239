import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import CustomLink from 'components/shared/CustomLink'

// Images
import ArrowRight from 'img/arrow-right.inline.svg'
import ArrowLeft from 'img/arrow-left.inline.svg'
import QuoteImage from 'img/quote.inline.svg'

const Wrapper = styled.div`
  top: 0;
  left: 0;
`

const Slide = styled(motion.div)`
  height: 220px;
`

const InfoWrapper = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  box-shadow: ${(props) => props.theme.shadow.main};
  border-radius: 10px;

  & a {
    @media (max-width: 780px) {
      padding: 0.6rem 3.7rem 0.6rem 1.1rem;
      font-size: 10px;
    }
  }
`

const InfoContent = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[36]};
    letter-spacing: ${(props) => props.theme.font.spacing.m};
    @media (max-width: 991px) {
      line-height: ${(props) => props.theme.font.size[40]};
    }

    @media (max-width: 767px) {
      font-size: ${(props) => props.theme.font.size[24]};
      line-height: ${(props) => props.theme.font.size[30]};
    }
  }

  & p {
    color: ${(props) => props.theme.color.text.secondary};
    letter-spacing: ${(props) => props.theme.font.spacing.s};
    & a {
      color: ${(props) => props.theme.color.text.light};
      letter-spacing: ${(props) => props.theme.font.spacing.m};
    }
  }
`

const SliderTitle = styled.h3`
  color: ${(props) => props.theme.color.text.contrast};
  font-size: ${(props) => props.theme.font.size[15]};
  letter-spacing: ${(props) => props.theme.font.spacing.xl};
`

const QuoteImageContainer = styled.div`
  top: -20px;
`

const Quote = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: ${(props) => props.theme.font.size[14]};
    letter-spacing: ${(props) => props.theme.font.spacing.s};
    @media (max-width: 575px) {
      font-size: ${(props) => props.theme.font.size[12]};
    }
  }
  & p {
    letter-spacing: ${(props) => props.theme.font.spacing.m};
    font-size: ${(props) => props.theme.font.size[22]};
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    @media (max-width: 991px) {
      -webkit-line-clamp: 3;
    }
    @media (max-width: 575px) {
      font-size: ${(props) => props.theme.font.size[18]};
    }
  }
`

const ArrowContainer = styled.div`
  & > button > svg {
    width: 22px;
    height: 19px;
    @media (max-width: 575px) {
      fill: ${(props) => props.theme.color.text.light};
    }
  }
`

const StyledCustomLink = styled(CustomLink)`
  color: ${(props) => props.theme.color.text.contrast};
  font-size: ${(props) => props.theme.font.size[16]};
  font-weight: ${(props) => props.theme.font.weight.l};
  letter-spacing: ${(props) => props.theme.font.spacing.m};

  & svg {
    width: 13px;
    height: 13px;
    max-width: 13px;
    max-height: 13px;
    fill: ${(props) => props.theme.color.text.contrast};
    margin-left: 8px;
  }

  &:hover {
    color: ${(props) => props.theme.color.text.contrast};
    transition: all 0.2s ease-in-out;

    & svg {
      margin-left: 18px;
    }
  }
`

const Background = styled.div`
  background-color: ${(props) => props.theme.color.face.dark};
  border-top-right-radius: 150px;
  &:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 2000px;
    background-color: ${(props) => props.theme.color.face.dark};
  }
`

interface CasesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Cases
  location?: any
}

const Cases: React.FC<CasesProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = React.useState<number>(0)

  return (
    <section className="position-relative">
      <Wrapper className="position-absolute w-100 h-100">
        <div className="container h-100">
          <div className="row h-100">
            <Background className="col-11 col-sm-8 h-100 position-relative" />
          </div>
        </div>
      </Wrapper>
      <div className="container">
        <div className="row py-5">
          <div className="col-10 col-sm-7 position-relative">
            <div style={{ minHeight: 350 }}>
              <SliderTitle className="my-5">{fields?.title}</SliderTitle>
              <AnimatePresence>
                {fields?.slide?.map((slide, index: number) => {
                  if (index !== currentIndex) {
                    return null
                  }

                  return (
                    <Slide
                      className="position-absolute"
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <QuoteImageContainer className="position-absolute">
                        <QuoteImage />
                      </QuoteImageContainer>
                      <Quote
                        content={slide?.description}
                        className="mb-5 px-4"
                      />
                      <StyledCustomLink
                        to={slide?.link?.url || '#'}
                        className="d-flex align-items-center"
                      >
                        {slide?.link?.title}
                        <ArrowRight />
                      </StyledCustomLink>
                    </Slide>
                  )
                })}
              </AnimatePresence>
            </div>
          </div>
          <div className="col-11 col-sm-5">
            <ArrowContainer className="ml-lg-5 ml-auto pl-lg-5 mt-4 mb-5 d-flex justify-content-lg-start justify-content-end">
              <button
                type="button"
                onClick={() => {
                  setCurrentIndex((prevIndex) => prevIndex - 1)
                }}
                disabled={currentIndex === 0}
                style={
                  currentIndex === 0 ? { opacity: '0.4' } : { opacity: '1' }
                }
              >
                <ArrowLeft />
              </button>
              <button
                type="button"
                onClick={() => {
                  setCurrentIndex((prevIndex) => prevIndex + 1)
                }}
                disabled={currentIndex + 1 === fields?.slide?.length}
                style={
                  currentIndex + 1 === fields?.slide?.length
                    ? { opacity: '0.4' }
                    : { opacity: '1' }
                }
              >
                <ArrowRight />
              </button>
            </ArrowContainer>
            <InfoWrapper className="p-lg-5 p-4">
              <InfoContent content={fields?.description} />
            </InfoWrapper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cases
