import React from 'react'
import styled from 'styled-components'
import CustomLink from 'components/shared/CustomLink'

const StyledCustomLink = styled(CustomLink)`
  color: ${(props) => props.theme.color.face.secondary};
`

interface ColoredLinkProps {
  to: string
  className?: string
  children: any
}

const ButtonDefault: React.FC<ColoredLinkProps> = ({
  to,
  className = '',
  children,
}) => (
  <StyledCustomLink to={to} className={className}>
    {children}
  </StyledCustomLink>
)

export default ButtonDefault
