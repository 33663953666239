/* eslint-disable no-undef */
import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import CustomLink from 'components/shared/CustomLink'

// Icons
import ChevronRight from 'img/chevron-right.inline.svg'

const Wrapper = styled.section<{ maxWidth: string }>`
  & .container {
    max-width: ${(props) =>
      props.maxWidth === 'compact' ? '1030px ' : '1130px'} !important;
  }
  /* @media (max-width: 575px) {
    margin-top: 8rem !important;
  } */
`

const Con = styled.div`
  & svg {
    max-width: 15px;
    max-height: 10px;
  }
`

const CrumbContainer = styled.div`
  &:last-child > a {
    color: ${(props) => props.theme.color.text.contrast};
  }
`

const Crumb = styled(CustomLink)`
  font-size: ${(props) => props.theme.font.size[16]};
  letter-spacing: ${(props) => props.theme.font.spacing.s};
  font-style: italic;
  color: ${(props) => props.theme.color.text.dark};
`

const CrumbLabel = styled.p`
  font-size: ${(props) => props.theme.font.size[16]};
  letter-spacing: ${(props) => props.theme.font.spacing.s};
  font-style: italic;
  color: ${(props) => props.theme.color.text.dark};
`

interface BreadcrumbsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Breadcrumbs
  location: any
  title: string
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  location,
  title = '',
  fields,
}) => {
  const { site } = useStaticQuery<GatsbyTypes.breadcrumbQueryQuery>(graphql`
    query breadcrumbQuery {
      site {
        siteMetadata {
          pathPrefix
          title
          description
          url
        }
      }
    }
  `)

  const paths = location.pathname
    .split('/')
    .filter((path: string) => path !== '')
    .filter((path: string) => path !== site?.siteMetadata?.pathPrefix)
    .map((path: string) => {
      let crumbLabel = path.replace(/-/g, ' ')

      if (title !== '' && location.pathname.replace(/\//g, '') === path) {
        crumbLabel = title
      }

      return {
        crumbLabel,
        pathname: `${site?.siteMetadata?.pathPrefix}/${path}/`,
      }
    })

  // Mag of Moet?
  const crumbs = [...[{ crumbLabel: 'Home', pathname: '/' }], ...paths]

  return (
    <Wrapper
      className="d-flex my-5 py-3"
      maxWidth={fields?.styletype || '1030px'}
    >
      <div className="container">
        <div className="d-flex">
          {crumbs.map(({ crumbLabel, pathname }, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <CrumbContainer key={index} className="d-flex align-items-center">
              {index !== 0 && (
                <Con>
                  <ChevronRight />
                </Con>
              )}
              {index !== 2 ? (
                <Crumb to={pathname}>
                  {crumbLabel.charAt(0).toUpperCase() + crumbLabel.slice(1)}
                </Crumb>
              ) : (
                <CrumbLabel className="mb-0">
                  {crumbLabel.charAt(0).toUpperCase() + crumbLabel.slice(1)}
                </CrumbLabel>
              )}
            </CrumbContainer>
          ))}
        </div>
      </div>
    </Wrapper>
  )
}

export default Breadcrumbs
